.section {
  &__graph-wrapper {
    width: 100%;
    min-height: 100vh;
  }

  &__graph-white-bg {
    width: 100%;
    height: 100vh;
    background: $grey;
    color: $primaryCharcoal;
  } 

  &__graph-text-wrapper {
    display: flex;
    height: 100vh;
    align-items: center;
		flex-direction: column;
		justify-content: flex-end;
    padding: 2rem;

    @include breakpoint(md) {
      flex-direction: row;
    }
  }

  &__graph-container {
    width: 100%;
    grid-template-rows: 1fr 100px;
    max-height: 31.25rem;

    @include breakpoint(md) {
      width: 60%;
    }
  }

	&__graph {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;

		& .graph-increase {
			position: absolute;
		}
  }

	&__graph-arrow {
		width: 100%;
		padding: .75rem;
	}

	&__graph-x {
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}

	&__graph-y {
		display: flex;
		justify-content: center;
		align-items: center;
		white-space: nowrap;
    text-align: center;
		max-width: 3.125rem;
		transform: rotate(-90deg);

    @include breakpoint(md) {
      max-width: unset;
		}
		
		& .copy-y-4 {
		}
	}

	&__graph-y-copy {
    margin-right: .5rem;
    transform: rotate(-90deg);

    @include breakpoint(md) {
      transform: none;
    }
	}

	&__graph-x-copy {
		margin-top: .5rem;
	}

	&__graph-y-copy,
	&__graph-x-copy {
		font-size: 1rem;

		@include breakpoint(sm) {
			font-size: 1.5rem;
		}

		@include breakpoint(md) {
			font-size: 1.75rem;
		}

		@include breakpoint(lg) {
			font-size: 2rem;
		}
	}

	&__graph-copy-container {
		display: flex;
		height: 100vh;
		position: relative;
		right: 0;
		align-items: center;
		padding: 1rem;

		@include breakpoint(md) {
      width: 30%;
			padding: 3rem;
		}
	}
	
	&__graph-copy {
		position: absolute;
	}

	&__graph-y-copy {
		position: absolute;
	}
}