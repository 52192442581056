$primaryBlue: #0074bc; 
$primaryCharcoal: #292c39;
$white: #ffffff; 
$grey: #efefef;

$secondaryCyan: #009fdf;
$secondaryGreen: #00c87d;
$secondaryPurple: #966ef5;
$secondaryRed: #f05a4c;
$secondaryYellow: #fabc28;
$secondaryGrey: #91a5af;

$cyanHover: #5cb0e0;
$greenHover: #6ad09b;
$purpleHover: #a68cf0;