.overlay {
	&__wrapper {
		width: 100vw;
		height: 100vh;
		background: #292c39;
		z-index: 999999;
		position: fixed;
		top: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		@include breakpoint(lg) {
			display: none;
		}
	}
}
