.layout__main-wrapper {
	position: relative;
	width: 100%;
	min-height: calc(100vh - 80px);
	z-index: 8;

	& > section {
		display: flex;
		align-items: center;
		padding: 1rem;

		@include breakpoint(sm) {
			padding: 3rem;
		}
	}
}

.layout__section {
	&-flex {
		position: relative;
		flex-direction: column;
		// 80px + 96px = 176px (header + section padding)
		height: calc(100vh - 176px);
		margin-top: 80px;
	}
}
