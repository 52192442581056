.video-player {
  position: absolute;
  top: 0;
  left: 0;

  &__container {
    display: flex;
    justify-content: center;
    position: relative;
    padding-top: 56.25%; 
    width: 100%;
  }

  &__title {
    padding: 1rem 0;
  }

  &__button {
    display: flex;
    align-items: center;
    font-size: 2rem;
    text-transform: uppercase;
    background: $primaryBlue;
    max-width: 19.5rem;
    width: 100%;
    justify-content: space-evenly;
  
    & .icon {
      display: flex;
      img {
        width: 3.125rem;
      }
    } 
  }
}


.button {
      &__text-line-1 {
      @include title-font();
    }

    // &__text-line-2 {

    // }
}
.modal__background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(28, 28, 28, 0.19);
  backdrop-filter: blur(6px);
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  transition: opacity 0.3s ease-out;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal__align {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.modal__content {
  width: 800px;
  height: 500px;
  box-shadow: 0px 100px 80px rgba(184, 184, 184, 0.07),
    0px 25.8162px 19px 4px rgba(178, 178, 178, 0.0456112),
    0px 7.779px 7.30492px rgba(0, 0, 0, 0.035),
    0px 1.48838px 2.0843px rgba(0, 0, 0, 0.0243888);
  background: transparent;
  color: #000;
  margin: 0rem 4rem;
}

.video-actions__container {
  position: relative;
}

.our-story {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  height: calc(100vh - 180px);
}

.modal__close {
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  bottom: 50px;
  width: 32px;
  height: 32px;
  padding: 0;
}

.modal__video-align {
  display: flex;
  position: relative;
  bottom: 37px;
}

.modal__video-style {
  border-radius: 20px;
  z-index: 100;
}

.modal__spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal__spinner {
  animation: spin 2s linear infinite;
  font-size: 40px;
  color: #1b6aae;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 800px) {
  .modal__content {
    margin: 0rem 1rem;
    width: 100%;
  }
  .modal__video-style {
    width: 100%;
  }
}

@media screen and (max-width: 499px) {
  .modal__content {
    background: transparent;
    height: auto;
  }
  .modal__video-align {
    bottom: 0px;
  }
  .modal__video-style {
    height: auto;
  }
}
