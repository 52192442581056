.header {
	$c: &;
	&__wrapper {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 9;
		width: 100%;
		height: 6rem;
	}

	&__title-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
	}
	&__title {
	}

	&__logo-link {
		display: flex;
		& > img {
			width: 150px;
			height: 100%;
		}
	}

	&__menu-wrapper {
		// font-family: Montserrat-Black, sans-serif;
		// position: absolute;
		// top: 0;
		// right: 0;
		// height: 100%;
		// display: flex;
		// justify-content: center;
		// align-items: center;
	}

	&__menu-button {
		& > #{$c}__menu-checkbox {
			&:checked {
				& ~ #{$c}__menu-content-wrapper {
					display: flex;
				}
			}
		}
	}

	&__menu-list {
		list-style: none;
		padding: 0;
	}

	&__menu-content-wrapper {
		position: absolute;
		right: 0;
		padding: 10px;
		width: 8rem;
		white-space: nowrap;
		display: none;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border: 2px solid #3c3c3c;
		background: #f9f9f9;
		border-radius: 10px;
		#{$c}__menu-link {
			padding: 0.5rem;
			text-decoration: none;
			color: #3c3c3c;
		}

		#{$c}__menu-item {
			display: flex;
		}
	}
}
