// breakpoints
$breakpoints: (
	xs: 32rem,
	sm: 48rem,
	md: 64rem,
	lg: 80rem,
	xl: 90rem,
	xxl: 120rem,
) !default;

@mixin breakpoint($breakpoint) {
	@media (min-width: map-get($map: $breakpoints, $key: $breakpoint)) {
		@content;
	}
}
