.animation {
	&__container {
		width: 100%;
		min-height: 100vh;
		margin-top: 80px;
	}

	&__particles-wrapper {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 200%;
		& #tsparticles {
			width: 100%;
			height: 100%;
		}
	}

	&__hero {
		width: 100vw;
		height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
		grid-row-start: 1;
	}

	&__copy {
		z-index: 1;
		height: 50vh;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		@include breakpoint(lg) {
			grid-row-start: auto;
			height: 100vh;
		}
		&.copy-spacer {
			display: none;
			@include breakpoint(lg) {
				display: flex;
				grid-row-start: auto;
			}
		}
		&.vertical {
			flex-direction: column;
			text-align: center;
		}
		&.copy-1 {
			grid-row-start: 3;
			@include breakpoint(lg) {
				grid-row-start: auto;
			}
		}
		&.copy-2 {
			grid-row-start: 4;
			@include breakpoint(lg) {
				grid-row-start: auto;
			}
		}
		&.copy-3 {
			grid-row-start: 5;
			@include breakpoint(lg) {
				grid-row-start: auto;
			}
		}
		&.copy-4 {
			grid-row-start: 6;
			@include breakpoint(lg) {
				grid-row-start: auto;
			}
		}
		&.copy-5 {
			grid-row-start: 7;
			@include breakpoint(lg) {
				grid-row-start: auto;
			}
		}
		&.copy-6 {
			grid-row-start: 8;
			@include breakpoint(lg) {
				grid-row-start: auto;
			}
		}
		&.copy-7 {
			grid-row-start: 9;
			@include breakpoint(lg) {
				grid-row-start: auto;
			}
		}
		&.copy-8 {
			grid-row-start: 10;
			@include breakpoint(lg) {
				grid-row-start: auto;
			}
		}
		&.copy-9 {
			grid-row-start: 11;
			@include breakpoint(lg) {
				grid-row-start: auto;
			}
		}
	}

	&__image {
		z-index: 999;
		height: 50vh;
		display: flex;
		justify-content: center;
		align-items: center;
		grid-row-start: 2;
		@include breakpoint(lg) {
			grid-row-start: auto;
			height: 100vh;
		}
	}

	&__image-container {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		& img {
			width: 65%;
			height: 65%;
			object-fit: contain;
		}
	}

	&__icon-container {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	&__icon {
		width: 15vw;
		height: 15vw;
		flex-shrink: 0;
		position: absolute;
		opacity: 0;
		@include breakpoint(lg) {
			width: 150px;
			height: 150px;
		}
		& img {
			width: 100%;
			height: 100%;
		}
		&:nth-child(1) {
			top: -1vw;
			right: -12vw;
			@include breakpoint(lg) {
				top: -10px;
				right: -120px;
			}
		}
		&:nth-child(2) {
			top: -10vw;
			left: -10vw;
			@include breakpoint(lg) {
				top: -100px;
				left: -100px;
			}
		}
		&:nth-child(3) {
			top: 20vw;
			left: -15vw;
			@include breakpoint(lg) {
				top: 200px;
				left: -150px;
			}
		}
		&:nth-child(4) {
			top: 30vw;
			right: -10vw;
			@include breakpoint(lg) {
				top: 300px;
				right: -100px;
			}
		}
		&:nth-child(5) {
			top: -19vw;
			left: 18vw;
			@include breakpoint(lg) {
				top: -190px;
				left: 180px;
			}
		}
	}

	&__circle {
		width: 60vw;
		height: 60vw;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
		background: radial-gradient(#fff, #00415c);
		border-radius: 100%;
		position: relative;
		@include breakpoint(md) {
			width: 40vw;
			height: 40vw;
		}
		@include breakpoint(lg) {
			width: 20vw;
			height: 20vw;
		}
	}

	&__up-arrow {
		// opacity: 0;
	}

	&__test-icon {
		width: 100px;
		height: 100px;
		background-color: red;
		border-radius: 100%;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
	}

	&__white-box {
		width: 100%;
		height: 100vh;
		background-color: #fff;
		z-index: 2;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 999;
	}

	&__graph-container {
		width: 60%;
		grid-template-rows: 1fr 100px;
		max-height: 31.25rem;
	}

	&__graph {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__graph-arrow {
		width: 100%;
		padding: .75rem;
	}

	&__graph-x {
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}

	&__graph-y {
		display: flex;
		justify-content: center;
		align-items: center;
		white-space: nowrap;
		text-align: center;
	}

	&__graph-y-copy {
		margin-right: .5rem;
	}

	&__graph-x-copy {
		margin-top: .5rem;
	}

	&__graph-y-copy,
	&__graph-x-copy {
		font-size: 1rem;

		@include breakpoint(sm) {
			font-size: 1.5rem;
		}

		@include breakpoint(md) {
			font-size: 1.75rem;
		}

		@include breakpoint(lg) {
			font-size: 2rem;
		}
	}

	&__graph-text-wrapper {
		display: flex;
		height: 100vh;
		align-items: center;
		padding: 1rem;

		@include breakpoint(md) {
			padding: 3rem;
		}
	}

	&__copy.graph-copy {
		font-size: 1.75rem;
		height: unset;
	}
}
