.navigation {
	position: absolute;
	top: 10px;
	right: 20px;
	padding: 100px 50px 20px 50px;
	border-radius: 10px;
	overflow: hidden;

	&__background {
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		width: 100%;
		background: #f9f9f9;
	}

	&__toggle-button {
		outline: none;
		border: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		cursor: pointer;
		position: absolute;
		top: 14px;
		right: 14px;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		background: transparent;
		pointer-events: all;
	}

	&__item-list,
	&__menu-item {
		margin: 0;
		padding: 0;
	}

	&__item-list {
		position: relative;
	}

	&__menu-item {
		position: relative;
		list-style: none;
		margin-bottom: 20px;
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	&__menu-item-link {
		display: flex;
		align-items: center;
		color: $primaryCharcoal;
		text-decoration: none;
		font-size: 1.125rem;

		img {
			width: 40px;
			height: 40px;
			margin-right: 16px;
		}
	}

	&.closed {
		pointer-events: none;
	}

	&.open {
		pointer-events: all;
	}

	&.dark {
		& .navigation__background {
			background: $primaryCharcoal;
		}

		&.closed .navigation__toggle-button {
			box-shadow: 6px 6px 12px #00000029;
			transition: all 3s;
		}

		svg path {
			stroke: $grey;
		}

		.navigation__menu-item-link {
			color: $grey;
		}
	}
}

.icon-placeholder {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	flex: 40px 0;
	margin-right: 20px;
}
