.intro {
	$c: &;
	&__wrapper {
		min-height: 100vh;
	}
	&__column {
		height: 100vh;
		min-height: 100vh;
	}
	&__image-container {
		position: relative;
		width: 250px;
		height: 250px;
		background-color: red;
		display: flex;
		flex-shrink: 0;
		justify-content: center;
		align-items: center;
		border-radius: 100%;
		background: radial-gradient(#fff, #00415c);
		z-index: 99;

		@include breakpoint(md) {
			width: 300px;
			height: 300px;
		}
		@include breakpoint(lg) {
			width: 500px;
			height: 500px;
		}
		
		&.no-bg {
			background: none;
		}

		& #{$c}__person {
			width: 50%;
		}
	}
	&__icon-container {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__icons {
		position: absolute;
		width: 50px;
		@include breakpoint(md) {
			width: 75px;
			height: 75px;
		}
		@include breakpoint(lg) {
			width: 100px;
			height: 100px;
		}
		&:nth-child(1) {
			top: -20px;
			left: 50px;
		}
		&:nth-child(2) {
			bottom: -30px;
			right: 25px;
		}
		&:nth-child(3) {
			left: -50px;
		}
		&:nth-child(4) {
			right: -50px;
		}
		&:nth-child(5) {
			top: 25px;
			right: 25px;
		}
		&:nth-child(6) {
			bottom: -25px;
			left: 50px;
		}
	}

	&__arrow-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 25%;
		@include breakpoint(lg) {
			margin-left: 0;
		}
		& #{$c}__arrow {
			width: 100%;
		}
	}

	&__green-arrow {
		justify-content: flex-start !important;
	}
	&__red-arrow {
		justify-content: flex-start !important;
		@include breakpoint(lg) {
			justify-content: center !important;
		}
	}
}

.section {
	&__column {
		height: 100vh;
		min-height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__column-spacer {
		display: none;
		@include breakpoint(lg) {
			display: flex;
		}
	}
	&__copy {
		width: 50%;
		display: flex;
		justify-content: center;
		align-items: center;

		@include breakpoint(lg) {
			width: 75%;
			margin-left: 0;
		}
	}
}

.copy-1 {
	grid-row-start: 2;
	@include breakpoint(lg) {
		grid-row-start: auto;
	}
}

.copy-7 > .section__copy {
	width: 75%;
	margin-left: 25%;
}

.green-arrow,
.red-arrow {
	margin-left: 12.5%;
	@include breakpoint(lg) {
		margin-left: 0;
	}
}

.section__pin-column {
	grid-row-start: 1;
	@include breakpoint(lg) {
		grid-row-start: auto;
	}
}

.intro__icon {
	position: absolute;
	width: 50px;
	@include breakpoint(md) {
		width: 75px;
		height: 75px;
	}
	@include breakpoint(lg) {
		width: 100px;
		height: 100px;
	}

	&:nth-child(1) {
		top: -20px;
		left: 50px;

		.intro__icons {
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
		}

		.pulse-before,
		.pulse-after {
			border-color: $purpleHover;
		}
	}
	&:nth-child(2) {
		bottom: -30px;
		right: 25px;

		.intro__icons {
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
		}

		.pulse-before,
		.pulse-after {
			border-color: $greenHover;
		}
	}
	&:nth-child(3) {
		left: -50px;
		
		.intro__icons {
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
		}

		.pulse-before,
		.pulse-after {
			border-color: $primaryBlue;
		}
	}
	&:nth-child(4) {
		right: -50px;

		.intro__icons {
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
		}

		.pulse-before,
		.pulse-after {
			border-color: $secondaryYellow;
		}
	}
	&:nth-child(5) {
		top: 25px;
		right: 25px;

		.intro__icons {
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
		}

		.pulse-before,
		.pulse-after {
			border-color: $secondaryRed;
		}
	}
	&:nth-child(6) {
		bottom: -25px;
		left: 50px;
	}
}

.pulse-before.active,
.pulse-after.active {
  position: absolute;
  border: 1px solid;
  left: -20px;
  right: -20px;
  top: -20px;
  bottom: -20px;
  border-radius: 50%;
  backface-visibility: hidden;
}

.pulse::before,
.pulse::after {
  content: "";
	background: black;
	border-radius: 50%;
	margin: 10px;
	height: 20px;
	width: 20px;

	box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	transform: scale(1);
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}
// .pulse::before {
//   transform: scale(var(--before-scale));
//   opacity: var(--before-opacity);
// }

// .pulse::after {
//   transform: scale();
//   opacity: ;
// }