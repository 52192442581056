.modal {
  width: clamp(50%, 800px, 90%);
  // height: min(50%, 500px);
  margin: auto;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  background: #000;

  &__background {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: $primaryCharcoal;
    opacity: 0.7;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 99;
  }

  &__close-button {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0.25rem;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}