.spinner {
  &__wrapper {
    display: flex;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 50%;
    z-index: 9;
  }

  &__circle {
    display: block;
    width: .5rem;
    height: .5rem;
    background: $primaryBlue;
    border-radius: .25rem;
  }
}