@import "./breakpoints.scss";
@import "./fonts.scss";
@import "./colours.scss";
@import "./header.scss";
@import "./animation.scss";
@import "./hero.scss";
@import "./layout.scss";
@import "./navigation.scss";
@import "./button.scss";
@import "./shapes.scss";
@import "./contact.scss";
@import "./video-player.scss";
@import "./spinner.scss";
@import "./intro.scss";
@import "./section2.scss";
@import "./modal.scss";
@import "./graph.scss";
@import "./flow.scss";
@import "./calculating.scss";
@import "./scroll-button.scss";
@import "./overlay.scss";

// look into this... does gsap work correctly with smooth
html {
	scroll-behavior: smooth !important;
}

body {
	margin: 0;
	font-family: "Montserrat", sans-serif;
	background: #292c39;
	color: #fff;
	font-size: 32px;
	font-weight: bold;
	-webkit-font-smoothing: antialiased;
	overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
}

p {
	@include copy-style();
}

button {
	font-family: "Montserrat", sans-serif;
	border: 0;
}

::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}
::-webkit-scrollbar-thumb {
	background: #ffffff;
	border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
	background: #ffffff;
}
::-webkit-scrollbar-track {
	background: #292c38;
	border-radius: 0px;
	box-shadow: inset 0px 0px 0px 0px #f0f0f0;
}
