.section {
  &__flow-wrapper {
    min-height: 100vh;

    &.light-bg {
      background: $grey;
      color: $primaryCharcoal;
    }

    & .icon-red,
    & .icon-green {
      border-radius: 20%;
      padding: .5rem;
    }
  }

  &__flow-chart-container {
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
      max-width: 9rem;
    }
  }
}