.shape {
  &__items-container {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  &__layout-container {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 42.5rem;
    height: 100%;
    min-height: 35rem;
    margin: 0 auto;
  }

  &-1 {
    position: absolute;
    right: 15%;
    bottom: 15%;
    height: 80px;
  }

  &-2 {
    position: absolute;
    right: 0;
    bottom: 40%;
    height: 80px;
  }

  &-3 {
    position: absolute;
    right: 16%;
    top: 16%;
    height: 80px;
  }

  &-4 {
    position: absolute;
    left: 12%;
    bottom: 9%;
    height: 150px;
  }

  &-5 {
    position: absolute;
    left: 0;
    bottom: 50%;
    height: 99px;
  }

  &-6 {
    position: absolute;
    left: 25%;
    top: 16%;
    height: 80px;
  }
}