.contact {
  justify-content: space-evenly;
  &__heading {
    font-size: 24px;
		text-align: center;
		text-transform: uppercase;
		@include breakpoint(sm) {
			font-size: 36px;
		}
		@include breakpoint(md) {
			font-size: 48px;
		}
		@include breakpoint(lg) {
			font-size: 72px;
		}
	}
	&__heading-line-1 {
		@include title-font();
		font-weight: 900;
	}
	&__heading-line-2 {
		font-weight: 900;
  }
  
  &__details-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__details {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-size: 1.5rem;
      font-weight: 900;
    }

    a {
      font-size: 1rem;
      font-weight: 900;
      color: $primaryBlue;
    }
  }

  &__details,
  &__address,
  &__social {
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    font-style: normal;
    padding: .75rem;
  }

  &__social {
    &-list {
      display: flex;
      margin: 1rem;
      list-style: none;
      padding: 0;
      align-items: baseline;
    }
  }

  & .social__link {
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 .5rem;

    svg {
      fill: $primaryBlue;
    }
  }
}