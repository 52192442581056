.calculating {
	&__container {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		position: relative;
		grid-row-start: 1;
		@include breakpoint(lg) {
			grid-row-start: auto;
		}
	}

	&__title-container {
		display: flex;
		justify-content: space-around;
		align-items: center;
		height: 33.33%;
		flex-direction: column;
	}
	&__image-container {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 33.33%;
		position: relative;
	}
	&__image-person {
		width: 135px;
		height: 159px;
		@include breakpoint(lg) {
			width: 180px;
			height: 212px;
		}
	}
	&__image-category-container {
		position: absolute;
		top: -60px;
		left: -110px;
	}
	&__person-container {
		position: relative;

		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__middle-copy-container {
		display: grid;
	}
	&__middle-copy {
		grid-column: 1/1;
		grid-row: 1/1;
		font-weight: normal;
		@include copy-style();
	}

	&__image-category {
		position: absolute;
		top: 0;
		left: 0;
		width: 112px;
		height: 112px;
		@include breakpoint(lg) {
			width: 150px;
			height: 150px;
		}
	}
	&__image-coherant {
	}
	&__image-literate {
	}
	&__image-on {
	}
	&__image-self {
	}
	&__image-ethical {
	}

	&__purple-arrow {
		width: 131px;
		height: 51px;
		margin: 10px;
		@include breakpoint(lg) {
			width: 175px;
			height: 68px;
			margin: 20px;
		}
	}

	&__person-middle {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	&__copy-container {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 33.33%;
	}

	&__copy {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__copy-bottom {
		display: flex;
		flex-direction: column;
		text-align: center;
		font-weight: normal;
		@include copy-style();
	}
	&__copy-bottom-container {
		display: flex;
		font-weight: bold;
	}

	&__copy-1 {
		text-align: center;
	}

	&__left-copy-container {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__left-copy {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 12.5%;
		box-sizing: border-box;
	}
	&__right-copy-container {
		position: relative;
	}
	&__right-copy {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 12.5%;
		box-sizing: border-box;
	}
	&__coherant {
		color: #fabc28;
	}
	&__literate {
		color: #00c87d;
	}
	&__on {
		color: #966ef5;
	}
	&__self {
		color: #91a5af;
	}
	&__ethical {
		color: #f05a4c;
	}

	&__column-1 {
		padding-top: 80px;
		box-sizing: border-box;
		height: calc(100% - 80px);
		display: none;
		@include breakpoint(lg) {
			display: flex;
		}
	}

	&__column-2 {
		padding-top: 80px;
		box-sizing: border-box;
		height: calc(100% - 80px);
	}
	&__column-3 {
		padding-top: 80px;
		box-sizing: border-box;
		height: calc(100% - 80px);
		display: none;
		@include breakpoint(lg) {
			display: flex;
		}
	}
	&__mobile-copy {
		display: flex;
		@include breakpoint(lg) {
			display: none;
		}
	}
	&__title-small-container {
		display: grid;
	}
	&__title-small {
		grid-column: 1/1;
		grid-row: 1/1;
		opacity: 0;
	}
	&__blue-copy {
		color: #009fdf;
	}
}
