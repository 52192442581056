.button {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.button {
  display: inline-block;
  padding: .8rem 1.5rem;
  outline: none;
  font-size: 1rem;
  font-weight: bold;
  font-style: normal;
  text-align: center;
  color: $white;
  cursor: pointer;
  border: 0;
  max-width: 22.5rem;
  border-radius: 3.125rem;
  margin: .6125rem;
  box-shadow: 6px 6px 12px #00000029;
  text-decoration: none;

  &.cyan {
    background: $secondaryCyan;
    transition: all 250ms;

    &:hover {
      background: $cyanHover;
    }
  }

  &.green {
    background: $secondaryGreen;
    transition: all 250ms;

    &:hover {
      background: $greenHover;
    }
  }

  &.purple {
    background: $secondaryPurple;
    transition: all 250ms;

    &:hover {
      background: $purpleHover;
    }
  }
}
