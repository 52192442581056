.section2 {
	&__wrapper {
		width: 100%;
		min-height: 100vh;

		div:nth-of-type(even) :first-child {
			// flex-flow switch 
		}
	}
	&__white-box {
		width: 100%;
		height: 100vh;
		background-color: $grey;
		color: $primaryCharcoal;
	}

	&__line {
		width: 100%;
		height: 10px;
		background-color: red;
		transform-origin: left;
	}
}
