.hero {
	&__wrapper {
		width: 100%;
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&__title {
		font-size: 24px;
		text-align: center;
		text-transform: uppercase;
		@include breakpoint(sm) {
			font-size: 36px;
		}
		@include breakpoint(md) {
			font-size: 48px;
		}
		@include breakpoint(lg) {
			font-size: 72px;
		}
	}
	&__title-line-1 {
		@include title-font();
		font-weight: 900;
	}
	&__title-line-2 {
		font-weight: 900;
	}
}
