.scroll {
  &__button-wrapper {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
  }

  &__button {
    display: flex;
    background: none;
    font-size: 1.5rem;
    font-weight: 700;
    color: $white;
    cursor: pointer;
    align-items: center;

    & .icon {
      width: 1.8125rem;
      margin: 1rem .6125rem .5rem;
    }
  }
}